$selection-width: 20px;
$selection-height: 20px;
$selection-border-width: 1px;
$selection-margin-right: 10px;
$selection-border-color-off: darken($light, 5%);
$selection-border-color-on: rgba($primary, 0.25);
$text-color: $primary;

// Your checkbox input
input[type="checkbox"] {
  border: 0;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;

  // Checked State
  &:checked {
    & + label {
      color: $primary !important;
      font-weight: bold !important;
    }
  }

  &:checked {
    & + label > .checkmark {
      height: $selection-height;
      animation: drawCheck ease-in-out 0.1s forwards;
    }

    & + label:before {
      border: $selection-border-width solid $selection-border-color-on;
      background: $primary;
      box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.25);
    }
  }

  // Checkbox Label
  & ~ label {
    display: block;
    line-height: $selection-height;
    cursor: pointer;
    padding: 0 0 0 $selection-width + $selection-margin-right;
    min-height: $selection-height;

    &:before {
      position: absolute;
      left: 0;
      content: "";
      width: $selection-width;
      height: $selection-height;
      float: left;
      border: $selection-border-width solid $selection-border-color-off;
      transition: 0.2s all ease-in-out;
      border-radius: 4px;
      box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.25);
      background-color: $white;
    }
  }
}

// General label style
label {
  text-align: left;
  position: relative;
  font-weight: 400 !important;
  color: $primary !important;
}

// The actual checkmark
.checkmark {
  width: $selection-width;
  height: 0;
  position: absolute;
  top: 5px;
  left: 5px;

  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAAEi6oPRAAAABGdBTUEAALGPC/xhBQAABUdJREFUeAHtm12oVUUUx+81S1JQUCstSoPSp6iIIiEseokKfIioJynoISKIMsToxQeTqJeIKIoIJCws6iEqgqIoKLJAjKIvwVQkpQ8o6cvMa/3Wac927Tlr9pl93B8e9gzMnZm1/mut//zvOXP23fvcqanW2r+00mIFQGGRhWnbrMw2Vxuz+Q1DZXDcKs6s/TgEcAYAOwXk1h2N02V1M34HghgAXxc2wWKTRrO+uAAQpxho5zigLNy8MDqHGwtOvRgJ0OCTd842dgu72eNSJMEMse4lWz0NCTaLmNLM6P9dg59rLACe2Qoz38LkNoArFDinrWzbcnDMhMBnVPBgGhOXMJOgAL/OBfRXT4grCU4ZvC74IYnyF90YWY9mMeY7ISqfY8L4SlSABVJJftb+wdmOU7Y4M03TTn8uSZzNxzqNPhGABroAN2qfn0QwOYMyIL78ELOSSCLHaEoDdFLmuxxOYyS4tEmSrB1jfNQtSoNw5lvTQAnW6xgm+dZKAsuPVR2Y5kmBfivAW3ChexNn48udKeIRcculrROi8i+uuhof7ILI64qAm37eBZGbXXU1/hNNhKAvVKBM74sOVkDiFnt53NL8bFChxSlR0y7SGy8tIsMrL84tV4QjIjxkOcNl8sZ5oXBwhzysLDeE8GPZSXiVUaTwGY7/LQOzc6yCsUEU3GAUPWzYjsTmrAUHAUsNx8u8Kqql8KgkMNjjWDBeMAqf/EmBpEBSICkwqQpwyj9F/4G+vPM9QOIret46JQSL13Im2aQzQtTf5JNh/VMnhCh8k0HG3dhrlxNELjLIFK4+W2MEkUUWGWzV/gqpgzFF9VMFzWtBHfkr59AM1Hxl5UR1BEBgRpFw0xujcxOxNYs6Fh0UAJLnV8dAjQ8E4LZZBQ6mNmq0leBv/Fysq9//MZLE3xjIeJLjTSPPZ6O3YSBI9IKR7JABNU3EPmzEn9gpTMIPjKR7TQbKSMwtRlxlhVXK41MSW6+B7ccRxRn4Swwy9Z7CFJDrFL8NvTABhO6U1P+nNcX+9Bmx3uz0YR46hZt7IEFR63C7TUgZZMV0oSPc2BgoLA+M/HZdYyT8xH5lY73ej2l0DYFTDRLO9GKjxUPJqS4Pkf22I4RvxQ6bZYrR/laKpiJJgaRAUiApkBRICiQFkgJJgb4qwMX3ufRv1UW4m8rXZJb1VRf5E/t0+kdOjcB4sJcCIcazAUF889ZeCcTu7/IVCKzljtOq3ojDZlfTre/tWPrc0SdhzkOB7y0VDNuTfRJG/vPsY0MEy/Q+xjl9Euc5SwXDthfb2X0S5m5DBMv0B8YrGxGGxNfSN9Lvp1/WSJGKSeFxDT32AL69Yvo4eCYIg9keistSLwomy+kHTEbDxifqre5lo95vwzULFnnWMngI5IXWvqSOHMDbC9XDi/dwNX8AU2RtmEPB8xerq2tXJUtI7i2FauHFHlzt/p8DBeX50xv0mCYPZGt7dEiue2KKgvmdfkVTv6CovBCYT99Bj2lfAloYldgAESsfCn/HFALTylvcoGmbICS3CfZFkn8b3Gl2pmEr2PPpByNzPz6c4SSysAn5EoX1TSBrf0+XUSdgHv1TK9CwvYstWvSyuq34IHs9/YixEcu0TpMCIP/f87wFNGzfYVui4ydqDvk7jU1ZpqMY19DvtZyGTQ7gyydKjDKybOYRY5PjmOQaa21ZrYn1sbFZ9JfGUSWLeWxiN1+FOJuVq98PKwj1DtjJOYCriFGGZdNn0neVCLUb31llOXrhQwT5SuM2utzt20+Xm+iLerH5tMmkQFIgKZAUSAp0osB/9Y6mJaAW00cAAAAASUVORK5CYII=)
    no-repeat;
  background-repeat: no-repeat;
  background-size: ($selection-width * 0.525) ($selection-height * 0.525);

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border: $selection-border-width solid $selection-border-color-off;
    width: $selection-width;
    height: $selection-height;
    opacity: 0;
  }
}

/* ---------------------------------------- Custom radiobutton  */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
// Your checkbox input
input[type="radio"] {
  position: absolute;
  border: 0;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;

  // Checked State
  &:checked {
    & + label {
      color: $primary !important;
      font-weight: bold !important;
    }
  }

  &:checked {
    & + label > .radiobutton {
      width: $selection-width - 10px;
      height: $selection-height - 10px;
      background: $primary;
      box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.25);
      animation: drawCheck ease-in-out 0.1s forwards;
    }

    & ~ label:before {
      border: $selection-border-width solid $selection-border-color-on;
    }
  }

  // radio Label
  & ~ label {
    display: block;
    line-height: $selection-height;
    cursor: pointer;
    padding: 0 0 0 $selection-width + $selection-margin-right;
    min-height: $selection-height;

    &:before {
      position: absolute;
      left: 0;
      content: "";
      width: $selection-width;
      height: $selection-height;
      float: left;
      border: $selection-border-width solid $selection-border-color-off;
      transition: 0.2s all ease-in-out;
      border-radius: 100px;
    }
  }
}

// // General label style
// label {
//   text-align: left;
//   position  : relative;
// }

// The actual checkmark
.radiobutton {
  width: $selection-width;
  height: $selection-height;
  position: absolute;
  top: 5px;
  left: 5px;
  background: transparent;
  background-size: ($selection-width * 0.9) ($selection-height * 0.9);
  border-radius: 100px;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border: $selection-border-width solid $selection-border-color-off;
    width: $selection-width;
    height: $selection-height;
    opacity: 0;
    background-color: $white;
  }
}

// Animate it: Sweep left to right
@keyframes drawCheck {
  from {
    transform: scale(1.5);
    transform-origin: center;
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

label {
  font-size: 0.875rem;
  color: $headings-color !important;
}
input {
  &::placeholder {
    color: $gray-600;
    font-weight: normal;
  }
}
input,
input:focus {
  @include default-inset(-2px);
  padding-left: 0.75rem !important;

  &::placeholder {
    color: $primary;
  }
}

.input-prepend-icon {
  position: relative;

  &__icon {
    position: absolute;
    top: 51%;
    left: 0.625rem;
    transform-origin: center center;
    transform: translateY(-50%);
    z-index: 3;
    font-size: 1.25rem;
    color: $gray-300;
  }

  input {
    text-indent: 1.5rem;
    z-index: 0;
  }

  .select2-selection__rendered {
    text-indent: 1.5rem;
  }
}

textarea {
  @include default-inset(-2px);
}
.form-control {
  text-indent: 0.5rem;
}
.form-control-sm {
  border-radius: $border-radius-lg;
}